import React from "react";
import { Link } from "gatsby";
import Image from "../Hacks/Image";

const PostContent = ({
  leftImage,
  leftUrl,
  leftTitle,
  rightImage,
  rightUrl,
  rightTitle,
  leftExcerpt,
  rightExcerpt,
}) => {
  return (
    <aside className="bg-light container-fluid">
      <div className="row justify-content-center contained-xl">
        <div className="col-lg-12 text-center">
          <h2 className="mb-4">Goodies and Opportunities</h2>
        </div>
        <div className="col-lg-5">
          <div className="card h-100">
            <div className="card-header p-0">
              <Link to={leftUrl}>
                <Image
                  image={leftImage}
                  className="img-fluid"
                  alt={leftTitle}
                />
              </Link>
            </div>
            <div className="card-body">
              <h3 className="h4">{leftTitle}</h3>
              <div className="mb-5">{leftExcerpt}</div>
              <div>
                <Link
                  to={leftUrl}
                  className="btn btn-sm btn-primary stretched-link"
                >
                  View
                </Link>
              </div>
            </div>
          </div>
        </div>
        {leftImage !== rightImage && (
          <div className="col-lg-5">
            <div className="card h-100">
              <div className="card-header p-0">
                <Link to={rightUrl}>
                  <Image
                    image={rightImage}
                    className="img-fluid"
                    alt={rightTitle}
                  />
                </Link>
              </div>
              <div className="card-body">
                <h3 className="h4">{rightTitle}</h3>
                <div className="mb-5">{rightExcerpt}</div>
                <div>
                  <Link
                    to={rightUrl}
                    className="btn btn-sm btn-primary stretched-link"
                  >
                    View
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </aside>
  );
};

export default PostContent;
